<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("Invoice.invoiceSuccess.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('Invoice.invoiceSuccess.search')"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view">
          <thead>
          <tr>
            <th class="text-left">
              {{ $t("Invoice.invoiceSuccess.table.invoiceNumber") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceSuccess.table.companyName") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceSuccess.table.phone") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceSuccess.table.package") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceSuccess.table.pricePerUser") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceSuccess.table.user") }}
            </th>

            <th class="text-left">
              {{ $t("Invoice.invoiceSuccess.table.totalPrice") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceSuccess.table.status") }}
            </th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in invoiceSuccess" :key="idx">
            <td>
              {{ item.invoice_number }}
            </td>
            <td>
              {{ item.company.name }}
            </td>
            <td>
              {{ item.company.phone }}
            </td>
            <td>
              {{ item.package.max_user }} ຄົນ
            </td>
            <td>
              {{ $helpers.numberFormatter(item.package.price_per_user) }}
            </td>
            <td>
              {{ item.total_user }} ຄົນ
            </td>
            <td>
              {{ $helpers.numberFormatter(item.sum_total) }}
            </td>
            <td>
              <div class="invoice-success">
                {{ item.status_invoice }}
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      invoiceSuccess: [],
    }
  },
  methods: {
    fetchInvoiceSuccess() {
      const status = "success"
      this.$axios.get(`admin/list/company/invoices/${status}`).then((res) => {
        if (res.data.code === 200) {
          this.invoiceSuccess = res.data.data;
        }
        this.$emit("success")
      })
    }
  },
  created() {
    this.fetchInvoiceSuccess();
  }
}
</script>

<style scoped lang="scss">
.invoice-success {
  font-size: 12px;
  letter-spacing: 1px;
  padding: 2px 14px;
  width: 80px;
  height: 20px;
  text-transform: capitalize;
  font-family: $font-family;
  border-radius: 60px;
  background-color: rgba(30, 144, 255, 1.0) !important;
  color: #FFFFFF;
}
</style>
