<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("Invoice.allInvoice.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <div class="generate-invoice" @click="createInvoice">
          <span><i class="fal fa-layer-plus"></i></span> {{ $t("Invoice.allInvoice.createInvoice") }}
        </div>
      </div>
      <div class="btn-new-create-action">
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view">
          <thead>
          <tr>
            <th class="text-left">
              {{ $t("Invoice.allInvoice.table.invoiceNumber") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.allInvoice.table.companyName") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.allInvoice.table.phone") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.allInvoice.table.package") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.allInvoice.table.pricePerUser") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.allInvoice.table.user") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.allInvoice.table.totalPrice") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.allInvoice.table.status") }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in allInvoice" :key="idx">
            <td>
              {{ item.invoice_number }}
            </td>
            <td v-if="item.company">
              {{ item.company.name }}
            </td>
            <td v-if="item.company">
              {{ item.company.phone }}
            </td>
            <td v-if="item.package">
              {{ item.package.max_user }} ຄົນ
            </td>
            <td v-if="item.package">
              {{ $helpers.numberFormatter(item.package.price_per_user) }}
            </td>
            <td>
              {{ item.total_user }} ຄົນ
            </td>
            <td>
              {{ $helpers.numberFormatter(item.sum_total) }}
            </td>
            <td>
              <div v-if="item.status_invoice === 'pending'">
                <span class="status pending">pending</span>
              </div>
              <div v-if="item.status_invoice === 'approved'">
                <span class="status approve">approve</span>
              </div>
              <div v-if="item.status_invoice === 'success'">
                <span class="status success">success</span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allInvoice: [],
      invoice_id: "",
    }
  },
  methods: {
    createInvoice() {
      this.$axios.get(`admin/calculate/company/invoices`).then((res) => {
        if (res) {
          this.$notification.GenerateInvoice('top-right', 'primary', 3000)
        }
        this.fetchAllInvoice();
      }).catch((error) => {
        console.log(error)
      })
    },
    fetchAllInvoice() {
      this.$axios.get(`admin/list/company/invoices/all`).then((res) => {
        if (res.status === 200) {
          this.allInvoice = res.data.data;
        }
      })
    }
  },

  created() {
    this.fetchAllInvoice();
  }

}
</script>

<style scoped lang="scss">
.generate-invoice {
  width: 200px;
  height: 45px;
  background-color: $main-btn-color;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #FFFFFF;
  font-family: $font-family;
  border-radius: 4px;
  transition: ease-out 0.2s;

  &:hover {
    transform: translateY(-5%);
  }

  span {
    margin-right: 8px;
  }
}

.status {
  font-size: 12px;
  letter-spacing: 1px;
  padding: 2px 8px;
  width: 60px;
  height: 20px;
  text-transform: capitalize;
  font-family: $font-family;
  border-radius: 60px;
}

.pending {
  background-color: rgba(255, 195, 18, 1.0);
  color: #FFFFFF;

}

.approve {
  background-color: rgba(38, 222, 129, 1.0);
  color: #FFFFFF;
}

.success {
  background-color: rgba(30, 144, 255, 1.0) !important;
  color: #FFFFFF;
}
</style>
