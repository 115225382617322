<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("Invoice.invoicePending.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('Invoice.invoicePending.search')"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-save-change" @click="approveInvoice" v-show="selectedInvoices.length >0">
          <span><i class="fal fa-layer-plus"></i></span>Approve Invoice
        </v-btn>
      </div>
    </div>
    <div class="section-form-content" style="overflow-x:auto;margin: 10px">
      <div class="block-content">
        <table class="table table-view">
          <thead>
          <tr>
            <th class="text-left" style="padding:10px 0;">
              <input type="checkbox" class="checkbox" v-model="selectAllInvoice"
                     @click="onSelectAllInvoices">
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoicePending.table.invoiceNumber") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoicePending.table.companyName") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoicePending.table.phone") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoicePending.table.package") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoicePending.table.pricePerUser") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoicePending.table.user") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoicePending.table.totalPrice") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoicePending.table.discount") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoicePending.table.sum_total_Price") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoicePending.table.status") }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in invoice" :key="idx">
            <td class="text-start">
              <label class="form-checkbox checkbox">
                <input type="checkbox" :value="item.id" v-model="selectedInvoices" @click="onSelectInvoice(item)">
                <i class="form-icon"></i>
              </label>
            </td>
            <td>
              {{ item.invoice_number }}
            </td>
            <td>
              <div v-if="item.company">
                {{ item.company.name }}
              </div>
              <div v-else>
                -
              </div>
            </td>
            <td>
              <div v-if="item.company">
                {{ item.company.phone }}
              </div>
              <div v-else>
                -
              </div>
            </td>
            <td v-if="item.package">
              {{ item.package.max_user }} ຄົນ
            </td>
            <td v-if="item.package">
              {{ $helpers.numberFormatter(item.package.price_per_user) }}
            </td>
            <td>
              {{ item.total_user }} ຄົນ
            </td>
            <td>
              {{ $helpers.numberFormatter(item.sub_total) }}
            </td>
            <td>
              {{ $helpers.numberFormatter(item.discount) }}
            </td>
            <td>
              {{ $helpers.numberFormatter(item.sum_total) }}
            </td>
            <td>
              <div class="pending">
                {{ item.status_invoice }}
              </div>
            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      @click="discountInvoice(item.id)"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      style="text-transform: capitalize;color: #0978EA"
                  >
                    <i class="far fa-tags"></i>
                    discount
                  </v-btn>
                </template>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>

        <ModalAdd title="Discount Invoice">
          <template v-slot="{ close }">
            <discountInvoice :invoice_id="invoice_id" @close="close" @success="fetchInvoices"/>
          </template>
        </ModalAdd>
      </div>
    </div>
  </div>
</template>

<script>
import discountInvoice from "@/views/MainAdmin/Invoice/discountInvoice";

export default {
  components: {
    discountInvoice
  },
  data() {
    return {
      invoice: [],
      selectedInvoices: [],
      selectAllInvoice: false,
      invoice_id: "",
    }
  },
  methods: {
    fetchInvoices() {
      const status = "pending"
      this.$axios.get(`admin/list/company/invoices/${status}`).then((res) => {
        if (res.data.code === 200) {
          this.invoice = res.data.data;
        }
        this.$emit("success")
      })
    },

    onSelectAllInvoices() {
      this.selectedInvoices = [];
      if (!this.selectAllInvoice) {
        for (let i in this.invoice) {
          this.selectedInvoices.push(this.invoice[i].id);
          console.log(this.selectedInvoices)
        }
      }
    },
    onSelectInvoice(item) {
      const index = this.selectedInvoices.findIndex((id) => {
        return id === item.id;
      });
      if (index !== -1) {
        this.selectedInvoices.splice(index, 1);
      } else {
        this.selectedInvoices.push(item.id);
      }
    },
    discountInvoice(invoice_id) {
      console.log(invoice_id)
      this.invoice_id = invoice_id;
      this.$store.commit("modalAdd_State", true);
    },
    approveInvoice() {
      this.$axios.post(`admin/approved/company/invoices`, {
        invoices: this.selectedInvoices,
      }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
        this.fetchInvoices();
      })
    },
  },
  created() {
    this.fetchInvoices();
  }
}
</script>

<style scoped lang="scss">
.pending {
  font-size: 12px;
  letter-spacing: 1px;
  padding: 2px 8px;
  width: 80px;
  height: 20px;
  text-transform: capitalize;
  font-family: $font-family;
  border-radius: 60px;
  background-color: rgba(255, 195, 18, 1.0);
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
