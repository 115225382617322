<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("Invoice.invoiceApprove.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('Invoice.invoiceApprove.search')"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-save-change" @click="approveInvoice" v-show="selectedInvoices.length >0">
          <span><i class="fal fa-layer-plus"></i></span>ຈ່າຍເງິນສົດ
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view">
          <thead>
          <tr>
            <th class="text-left" style="padding:10px 0;">
              <input type="checkbox" class="checkbox" v-model="selectAllInvoice"
                     @click="onSelectAllInvoices">
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceApprove.table.invoiceNumber") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceApprove.table.companyName") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceApprove.table.phone") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceApprove.table.package") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceApprove.table.pricePerUser") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceApprove.table.user") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceApprove.table.totalPrice") }}
            </th>

            <th class="text-left">
              {{ $t("Invoice.invoiceApprove.table.discount") }}
            </th>

            <th class="text-left">
              {{ $t("Invoice.invoiceApprove.table.sum_total_Price") }}
            </th>
            <th class="text-left">
              {{ $t("Invoice.invoiceApprove.table.status") }}
            </th>
            <th class="text-end">
              {{ $t("Invoice.invoiceApprove.table.discount") }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in invoiceApprove" :key="idx">
            <td class="text-start">
              <label class="form-checkbox checkbox">
                <input type="checkbox" :value="item.id" v-model="selectedInvoices" @click="onSelectInvoice(item)">
                <i class="form-icon"></i>
              </label>
            </td>
            <td>
              {{ item.invoice_number }}
            </td>
            <td>
              <div v-if="item.company">
                {{ item.company.name }}
              </div>
              <div v-else>
                -
              </div>
            </td>
            <td>
              <div v-if="item.company">
                {{ item.company.phone }}
              </div>
              <div v-else>
                -
              </div>
            </td>
            <td>
              {{ item.package.max_user }} ຄົນ
            </td>
            <td>
              {{ $helpers.numberFormatter(item.package.price_per_user) }}
            </td>
            <td>
              {{ item.total_user }} ຄົນ
            </td>
            <td>
              {{ $helpers.numberFormatter(item.sub_total) }}
            </td>
            <td>
              <div>
                <span v-if="item.discount"> {{ $helpers.numberFormatter(item.discount) }}</span>
                <span v-else>-</span>
              </div>
            </td>
            <td>
              {{ $helpers.numberFormatter(item.sum_total) }}
            </td>
            <td>
              <div class="approve">
                {{ item.status_invoice }}
              </div>
            </td>
            <td>
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <vs-tooltip style="float: right" ref="addTooltip">
                    <v-btn
                        @click="updateDiscountInvoiceApprove(item)"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                    >
                      <i style="font-size: 22px" class="far fa-pencil-alt"></i>
                    </v-btn>
                    <template #tooltip>
                      update invoice
                    </template>
                  </vs-tooltip>

                </template>
              </v-menu>
            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <vs-tooltip style="float: right" ref="addTooltip">
                    <v-btn
                        @click="downloadInvoice(item)"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                    >
                      <i style="font-size: 22px" class="far fa-file-pdf"></i>
                    </v-btn>
                    <template #tooltip>
                      Download Invoice
                    </template>
                  </vs-tooltip>

                </template>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modalInvoiceApprove :visible="modalInvoiceApprove"
                         :invoice_id="invoice_id"
                         @success="fetchInvoiceApprove"
                         @change="(val) => modalInvoiceApprove = val"/>
  </div>
</template>

<script>
import modalInvoiceApprove from "@/views/MainAdmin/Invoice/Modal/modalInvoiceApprove";

export default {
  components: {
    modalInvoiceApprove,
  },
  data() {
    return {
      invoiceApprove: [],
      selectedInvoices: [],
      selectAllInvoice: false,
      modalInvoiceApprove: false,
      invoice_id: "",
    }
  },
  methods: {

    updateDiscountInvoiceApprove(item) {
      this.invoice_id = item.id;
      this.modalInvoiceApprove = true;
    },
    onSelectAllInvoices() {
      this.selectedInvoices = [];
      if (!this.selectAllInvoice) {
        for (let i in this.invoiceApprove) {
          this.selectedInvoices.push(this.invoiceApprove[i].id);
        }
      }
    },
    onSelectInvoice(item) {
      const index = this.selectedInvoices.findIndex((id) => {
        return id === item.id;
      });
      if (index !== -1) {
        this.selectedInvoices.splice(index, 1);
      } else {
        this.selectedInvoices.push(item.id);
      }
    },

    approveInvoice() {
      this.$axios.post(`admin/payment/cash/company/invoices`, {
        invoices: this.selectedInvoices,
      }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
        this.fetchInvoiceApprove();
      })
    },

    fetchInvoiceApprove() {
      const status = "approved"
      this.$axios.get(`admin/list/company/invoices/${status}`).then((res) => {
        if (res.data.code === 200) {
          this.invoiceApprove = res.data.data;
        }
        this.$emit("success")
      })
    },

    downloadInvoice(item) {
      const invoice_id = item.id;
      this.$axios.get(`admin/download/company/invoice/${invoice_id}`, {responseType: 'blob'})
          .then((response) => {
            const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileUrl
            fileLink.setAttribute('download', 'invoice.pdf')
            document.body.appendChild(fileLink)
            fileLink.click()
            document.body.removeChild(fileLink)
          }).catch((e) => console.log(e))
    },
  },
  created() {
    this.fetchInvoiceApprove();
  }
}
</script>

<style scoped lang="scss">
.approve {
  font-size: 12px;
  letter-spacing: 1px;
  padding: 2px 8px;
  width: 80px;
  height: 20px;
  text-transform: capitalize;
  font-family: $font-family;
  border-radius: 60px;
  background-color: rgba(38, 222, 129, 1.0);
  color: #FFFFFF;
}
</style>
