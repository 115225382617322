<template>
  <div class="discount">
    <v-form ref="form" style="width: 100%;margin-left: 200px;margin-top: 30px;">
      <v-row>
        <v-col cols="8" md="8" lg="8">
          <label class="label-input">{{ $t("Invoice.discount") }}</label>
          <vuetify-money
              dense
              :outlined="true"
              :options="$store.state.formatSalaryNumber"
              :clearable="true"
              v-model="items.discount"
          />

          <v-btn class="btn-save-change" @click="updateDiscountInvoice">
            {{ $t("Invoice.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "discountInvoice",
  props: {
    invoice_id: {},
  },
  data() {
    return {
      items: {
        discount: "",
      }
    }
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
    },

    updateDiscountInvoice() {
      this.$axios.post(`admin/discount/company/invoice/${this.invoice_id}`, this.items).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
        this.$emit("success");
        this.$emit("close")
        this.resetForm();
      })
    }
  },
}
</script>

<style scoped lang="scss">
.discount {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
</style>
