<template>
  <v-dialog
      v-model="modalInvoiceApprove"
      width="800"
      persistent
      @close="setModalInvoiceApprove(false)"
      transition="scroll-x-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete" style="float: right;">
        <v-btn
            icon
            depressed
            @click="setModalInvoiceApprove(false)"
        >
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>

      <div class="invoice-container">
        <v-row dense>
          <v-col cols="12" md="12" lg="12">
            <label class="label-input">{{ $t("Invoice.discount") }}</label>
            <vuetify-money
                dense
                :outlined="true"
                :options="$store.state.formatSalaryNumber"
                :clearable="true"
                v-model="item.discount"
            />
          </v-col>
          <v-col cols="12" md="12" lg="12">
            <v-btn class="btn-save-change" @click="updateInvoiceApprove">
              {{ $t("Invoice.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    invoice_id: {},
    visible: {
      default: false,
    },
    icon: {
      default: 'mdi-close',
      type: String
    },
  },
  data() {
    return {
      modalInvoiceApprove: false,
      item: {
        discount: "",
      }
    }
  },

  methods: {
    resetForm() {
      this.item.discount = "";
    },
    setModalInvoiceApprove(isVisible) {
      this.modalInvoiceApprove = isVisible;
      this.$emit('change', isVisible);
    },
    updateInvoiceApprove() {
      const invoices = [];
      invoices.push(this.invoice_id);
      this.$axios.post(`admin/discount/company/invoice/${this.invoice_id}`, this.item).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.$emit("success");
          this.$emit("close")
          this.resetForm();
          this.$axios.post(`admin/approved/company/invoices`, {
            invoices: invoices,
          }).then((res) => {
            if (res.status === 200) {
              this.setModalInvoiceApprove(false)
            }
          }).catch((error) => {
            console.log(error)
          })
        }


      })
    }
  },
  watch: {
    visible(val) {
      this.modalInvoiceApprove = val;
    },
  },
}
</script>

<style scoped lang="scss">
.invoice-container {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 80px;
}
</style>
