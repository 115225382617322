<template>
  <div class="step-register">
    <v-stepper class="card-step-register" v-model="e1">
      <v-stepper-header>
        <template v-for="n in steps">
          <v-stepper-step
              :key="`${n.id}-step`"
              :complete="e1 > n.id"
              :step="n.id"
              editable
              @click="handleStepper(n.id)"
          >
            <span v-if="$i18n.locale === 'en'">{{ n.stepName_en }}</span>
            <span v-else> {{ n.stepName }}</span>
          </v-stepper-step>

          <v-divider
              v-if="n.id !== steps.id"
              :key="n.id"
          ></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
            v-for="n in steps"
            :key="`${n.id}-content`"
            :step="n.id"
        >
          <invoiceAllStatus ref="AllInvoiceStatus" v-if="n.id === 1"/>
          <invoicePending ref="invoicePending" v-if="n.id === 2"/>
          <invoiceApprove ref="invoiceApprove" v-if="n.id === 3"/>
          <invoiceSuccess ref="invoiceSuccess" v-if="n.id === 4"/>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>

import invoicePending from "@/views/MainAdmin/Invoice/invoiceStatus/invoicePending";
import invoiceAllStatus from "@/views/MainAdmin/Invoice/invoiceStatus/invoiceAllStatus";
import invoiceApprove from "@/views/MainAdmin/Invoice/invoiceStatus/invoiceApprove";
import invoiceSuccess from "@/views/MainAdmin/Invoice/invoiceStatus/invoiceSuccess";

export default {
  components: {
    invoicePending,
    invoiceAllStatus,
    invoiceApprove,
    invoiceSuccess
  },
  data() {
    return {
      e1: 1,
      steps: [
        {
          id: 1,
          stepName_en: "All Invoice",
          stepName: "ໃບແຈ້ງໜີ້ທັງໝົດ"
        },

        {
          id: 2,
          stepName_en: "Invoice Pending",
          stepName: "ໃບແຈ້ງໜີ້ລໍຖ້າດຳເນີນການ"
        },
        {
          id: 3,
          stepName_en: "Invoice Approve",
          stepName: "ໃບແຈ້ງໜີ້ອະນຸມັດແລ້ວ"
        },
        {
          id: 4,
          stepName_en: "Invoice Success",
          stepName: "ໃບແຈ້ງໜີ້ທີ່ສຳເລັດ",
        },
      ]
    }
  },

  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    },
  },

  methods: {
    handleStepper(value) {
      if (value === 1) {
        this.$refs.AllInvoiceStatus[0].fetchAllInvoice();
      }
      if (value === 2) {
        this.$refs.invoicePending[0].fetchInvoices();
      }
      if (value === 3) {
        this.$refs.invoiceApprove[0].fetchInvoiceApprove();
      }
      if (value === 4) {
        this.$refs.invoiceSuccess[0].fetchInvoiceSuccess();
      }
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1
      } else {
        this.e1 = n + 1
      }
    },


    validate(n) {
      this.steps[n].valid = false
      let v = this.$refs.stepForm[n].validate()
      if (v) {
        this.steps[n].valid = true;
      }
    },
    nexStepRegister(step) {
      this.nextStep(step);
    },

  },
}
</script>

<style scoped lang="scss">
.step-register {
  width: 100%;
  height: auto;
  padding: 10px;
  background-color: #eeeeee;
  //background-color: #FFFFFF;

  .card-step-register {
    width: 100%;
    height: auto;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
  }

}
</style>
